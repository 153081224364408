import { Controller } from "stimulus";
import { getActiveCurrency } from "../../util/currency";
import { buildChartAxes, buildChartConfig, buildChartSeries, buildOhlcChartConfig } from "../../template/charts/price_chart";
import { EventCurrencyChanged, EventGeckoChartNavigatorAdjusted, EventGeckoChartRendered } from "../../events";
import { loadHighcharts } from "../../util/load_package";
import { memoizedFetcher } from "../../util/memoization";

let Highcharts;
const _24_HOURS_IN_SECS = 24 * 60 * 60;
const _48_HOURS_IN_SECS = 48 * 60 * 60;

export default class extends Controller {
  // Provided values, from data attributes.
  vsCurrency = null;
  dataUrl = null;
  dataLabel = null;
  maxLongerCacheDataUrl = null; // For mini navigator chart
  customDataUrl = null;
  showBtcAxis = false;
  showEthAxis = false;
  scaleType = "linear";
  ohlcChart = false;

  // Inferred values, not from data attributes.
  chartInstance = null;
  maxLongerCacheData = null;
  btcDataUrl = null;
  ethDataUrl = null;
  usdDataUrl = null;

  // One of 3 values; used in the render event to inform others.
  // - "default" - one of the predefined range, or static
  // - "custom" - when range selected with calendar or navigator
  activeDataType = "default";
  activeCurrency = getActiveCurrency();
  listeningCurrencyChange = false;


  async connect() {
    Highcharts = await loadHighcharts();
    this.rerenderChart();
  }


  fetchData() {
    const promises = [];

    // Main data, volume data
    let mainData = memoizedFetcher.fetchWithCache(this.dataUrl);
    promises.push(mainData);

    // Main data (max duration for mini navigator chart)
    if (this.maxLongerCacheDataUrl && !this.maxLongerCacheData) {
      promises.push(this.maxLongerCacheData = memoizedFetcher.fetchWithCache(this.maxLongerCacheDataUrl));
    } else {
      promises.push(Promise.resolve(this.maxLongerCacheData));
    }

    // BTC data
    if (this.showBtcAxis && this.btcDataUrl) {
      let btcData = memoizedFetcher.fetchWithCache(this.btcDataUrl).then(data => ({ key: "btc", data }));
      promises.push(btcData);
    } else {
      promises.push(Promise.resolve());
    }

    // ETH data
    if (this.showEthAxis && this.ethDataUrl) {
      let ethData = memoizedFetcher.fetchWithCache(this.ethDataUrl).then(data => ({ key: "eth", data }));
      promises.push(ethData);
    } else {
      promises.push(Promise.resolve());
    }

    // NFT USD data
    if (this.showUsdAxis && this.usdDataUrl) {
      let usdData = memoizedFetcher.fetchWithCache(this.usdDataUrl).then(data => ({ key: "usd", data }));
      promises.push(usdData);
    } else {
      promises.push(Promise.resolve());
    }

    return Promise.all(promises);
  }


  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.fetchData().then(([mainData, maxLongerCacheData, btcData, ethData, usdData]) => {
      let chartConfig;
      if (this.nftChart) {
        const primaryData = mainData?.stats;
        const volumeData = mainData?.total_volumes;

        chartConfig = buildChartConfig(
          this.element,
          buildChartAxes(this.vsCurrency, this.scaleType, !!volumeData, false, usdData),
          buildChartSeries(primaryData, this.dataLabel, volumeData, usdData),
          this.vsCurrency,
          maxLongerCacheData?.stats,
          this.handleCustomData.bind(this)
        );
      } else if (!this.ohlcChart) {
        // if the data is an array, it's coming from total market data or altcoin market data
        const primaryData = mainData?.stats || (Array.isArray(mainData) ? mainData.find(x => x.name === "market_cap").data : undefined);
        const volumeData = mainData?.total_volumes || (Array.isArray(mainData) ? mainData.find(x => x.name === "volume").data : undefined);

        chartConfig = buildChartConfig(
          this.element,
          buildChartAxes(this.activeCurrency, this.scaleType, !!volumeData, this.element.dataset.stablecoin, btcData, ethData),
          buildChartSeries(primaryData, this.dataLabel, volumeData, btcData, ethData),
          this.activeCurrency,
          maxLongerCacheData?.stats,
          this.handleCustomData.bind(this),
        );
      } else {
        chartConfig = buildOhlcChartConfig(
          this.element,
          mainData?.ohlc,
          this.scaleType,
          this.activeCurrency
        );
      }

      this.chartInstance = Highcharts.StockChart(this.element.id, chartConfig);

      // Inform other controllers that the chart was rendered recently.
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartRendered, {
        bubbles: true,
        detail: { type: this.activeDataType, chart: this.chartInstance }
      }));

      // Rerender chart on currency change event, register event once only.
      if (!this.listeningCurrencyChange) {
        this.listeningCurrencyChange = true;
        window.addEventListener(EventCurrencyChanged, (e) => {
          // Invalidate maxLongerCacheData because the data was for the previous currency.
          this.maxLongerCacheData = null;
          this.activeCurrency = e.detail.currencyCode;
          this.rerenderChart();
        });
      }
    });
  }


  handleCustomData(e) {
    if (e.trigger !== "navigator" && e.trigger !== "calendar") {
      return;
    }

    if (this.customDataUrl) {
      let customURL = new URL(this.customDataUrl);
      let fromTs = Math.round(e.min / 1000);
      let toTs = Math.round(e.max / 1000);

      // Range is less than 24 hours, make it 48 hours
      if (toTs - fromTs <= _48_HOURS_IN_SECS) {
        fromTs = fromTs - _24_HOURS_IN_SECS;
        toTs = toTs + _24_HOURS_IN_SECS;
      }

      customURL.searchParams.set("from", fromTs);
      customURL.searchParams.set("to", toTs);

      this.element.dataset.dataUrl = customURL.href;
      this.rerenderChart();
    } else {
      // Inform other controllers that the chart navigator was adjusted
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartNavigatorAdjusted, {
        bubbles: true,
        detail: {
          trigger: e.trigger,
        }
      }));
    }
  }


  rerenderChart() {
    this.vsCurrency = this.element.dataset.vsCurrency;
    this.dataLabel = this.element.dataset.dataLabel || I18n.t("charts.price");
    this.dataUrl = this.element.dataset.dataUrl;
    this.maxLongerCacheDataUrl = this.element.dataset.maxLongerCacheDataUrl;
    this.customDataUrl = this.element.dataset.customDataUrl;
    this.scaleType = this.element.dataset.scaleType || "linear";
    this.ohlcChart = this.element.dataset.ohlcChart === "true";
    this.nftChart = this.element.dataset.nftChart === "true";

    // If vsCurrency is set, then this means the data URLs support multi-currency.
    if (this.vsCurrency && !this.nftChart) {
      this.dataUrl = this.dataUrl.replace(`/${this.vsCurrency}/`, `/${this.activeCurrency}/`);
      this.maxLongerCacheDataUrl = this.maxLongerCacheDataUrl?.replace(`/${this.vsCurrency}/`, `/${this.activeCurrency}/`);
      this.customDataUrl = this.customDataUrl?.replace(`/${this.vsCurrency}/`, `/${this.activeCurrency}/`);

      this.btcDataUrl = this.dataUrl.replace(`/${this.activeCurrency}/`, "/btc/");
      this.ethDataUrl = this.dataUrl.replace(`/${this.activeCurrency}/`, "/eth/");
    }

    // If NFT Chart
    if (this.nftChart) {
      const originalUrl = new URL(this.dataUrl);
      const searchParams = new URLSearchParams(originalUrl.search);
      // Only replace the currency to USD
      searchParams.set("currency", "usd")
      // use the original url and pathname but replace the search params with the updated one
      const urlWithUSDCurrency = new URL(`${originalUrl.origin}${originalUrl.pathname}?${searchParams.toString()}`);

      this.usdDataUrl = urlWithUSDCurrency.href
      this.showUsdAxis = this.element.dataset.showUsd === "true";
    }

    // We can only show the BTC/ETH axes if multi-currency is supported on the data URL.
    this.showBtcAxis = this.vsCurrency && this.element.dataset.showBtc === "true";
    this.showEthAxis = this.vsCurrency && this.element.dataset.showEth === "true";

    if (this.dataUrl.includes(this.customDataUrl)) {
      this.activeDataType = "custom";
    } else {
      this.activeDataType = "default";
    }

    this.renderChart();
  }


  // Watch for re-renders with the "data-price-chart-rerender-value" attribute.
  // Enables triggering a re-render from other controllers by changing the attribute value.
  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  static values = { rerender: Boolean };
  rerenderValueChanged() {
    if (!Highcharts || this.rerenderValue === false) {
      return;
    }

    this.rerenderValue = false;
    this.rerenderChart();
  }
}
