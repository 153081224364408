import { AD_INTEREST_KEYS, USER_UUID_KEY } from "../config";

const KEVEL_ASSET_URL = "https://s.zkcdn.net/Advertisers/";
const GECKAD_ASSET_URL = "https://assets.geckad.com/";
const USER_DB_URL = "https://geckad.com/kevel/userdb/";

export const getAdData = async (requestBody) => {
  const endpoint = document.body.dataset.kevelApiProxyEndpoint;

  // add local uuid if user not logged in
  if (!!requestBody.user === false) {
    requestBody.user = { key: localStorage.getItem(USER_UUID_KEY) };
  }

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(requestBody),
  };
  
  let adDataCollections = [];

  try {
    const response = await fetch(endpoint, requestOptions);
    const data = await response.json();
    adDataCollections = Object.values(data.decisions || {})
      .flat()
      .filter(ad => ad != null);
  } catch (e) {
    Sentry.captureException(e);
  }

  if (adDataCollections.length === 0) {
    return [{
      link: null,
      impressionLink: null,
      adContentData: null,
      flightId: null,
    }];
  } else {
    return adDataCollections.map(ad => {
      const adContentData = ad.contents?.[0]?.data;
      return {
        link: ad.clickUrl,
        impressionLink: ad.impressionUrl,
        adContentData: adContentData,
        flightId: ad.flightId,
      };
    });
  };
};

export const sendImpression = async (endpoint) => {
  try {
    const response = await fetch(endpoint);
    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Error sending impression!");
    }
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
};

export const replaceAssetURL = (url) => {
  if (url !== undefined) {
    return url.replace(KEVEL_ASSET_URL, GECKAD_ASSET_URL);
  }
};

export const syncUserDb = async () => {
  const body = buildInterestDataBody();
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(USER_DB_URL, requestOptions);
    const responseBody = await response.text();
  } catch (e) {
    Sentry.captureException(e);
  }

  if (response.status === 200) {
    return true;
  } else {
    Sentry.captureMessage(`Error sync user DB: ${responseBody}`);
    return false;
  }
};

const buildInterestDataBody = () => {
  const uuid = localStorage.getItem(USER_UUID_KEY);
  let interestData = {};

  for (const key in AD_INTEREST_KEYS) {
    const data = localStorage.getItem(AD_INTEREST_KEYS[key]);
    if (!!data) {
      interestData[key] = JSON.parse(data);
    }
  }

  return { uuid: uuid, interest_data: interestData };
};

