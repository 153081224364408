import { Controller } from "stimulus";
import {
  trackEvent,
  unconditionalTrackEvent,
  processTabIdToEventProp,
} from "../../analytics";
import { SELECT_COIN_INFO, SELECT_COIN_MAIN_TAB } from "../../mixpanel_config";

export default class extends Controller {

  connect() {
    this.activeButtonIdentifers = {}
    this.tempEventProperties = {}
    this.tempEventName = ""
    this.oneTimeEvents = []
  }

  trackEvent(e) {
    trackEvent(e?.currentTarget?.dataset?.analyticsEvent, this._getEventAttributes(e))
  }

  unconditionalTrackEvent(e) {
    unconditionalTrackEvent(e?.currentTarget?.dataset?.analyticsEvent, this._getEventAttributes(e))
  }

  tabTrackEvent(e) {
    const analyticsEvent = e.currentTarget?.dataset?.analyticsEvent

    if (Object.keys(this.activeButtonIdentifers).length === 0 && e.currentTarget.dataset.activeButton === e.currentTarget.id) {
      return
    }

    if (this.activeButtonIdentifers[analyticsEvent] === e.currentTarget.id) {
      return
    }

    this.activeButtonIdentifers[analyticsEvent] = e.currentTarget.id

    trackEvent(analyticsEvent, { tab_name: e.currentTarget.dataset.tabName })
  }

  coinInfoTrackEvent(e) {
    const interactionType = e.currentTarget.dataset.interactionType;
    const infoType = e.currentTarget.dataset.infoType;
    const infoValue = e.currentTarget.dataset.infoValue;

    trackEvent(SELECT_COIN_INFO, { interaction_type: interactionType, info_type: infoType, info_value: infoValue })
  }

  oneTimeTrackEvent(e, conditional = false) {
    if (!this.oneTimeEvents.includes(e.currentTarget.dataset?.analyticsEvent)) {
      if (conditional || e.currentTarget.dataset?.analyticsConditional) {
        trackEvent(e.currentTarget.dataset?.analyticsEvent, this._getEventAttributes(e))
      } else {
        unconditionalTrackEvent(e.currentTarget.dataset?.analyticsEvent, this._getEventAttributes(e))
      }

      this.oneTimeEvents.push(e.currentTarget.dataset?.analyticsEvent)
    }
  }

  buttonGroupTrackEvent(e) {
    if (e.currentTarget.dataset.activeButton !== e.currentTarget.id) {
      unconditionalTrackEvent(e.currentTarget.dataset?.analyticsEvent, this._getEventAttributes(e))
    }
  }

  storeTempEventProperties(e) {
    if (!e.currentTarget.dataset.analyticsTempEventName) return

    this.tempEventName = e.currentTarget.dataset.analyticsTempEventName

    if (e.currentTarget.dataset.analyticsEventProperties) {
      this.tempEventProperties = JSON.parse(e.currentTarget.dataset.analyticsEventProperties)
    }
  }

  trackEventFromTempProperties(e) {
    let analyticsEvent = this.tempEventName;

    if (analyticsEvent) {
      if (e.currentTarget.dataset.analyticsEventProperties) {
        this.tempEventProperties = { ...this.tempEventProperties, ...JSON.parse(e.currentTarget.dataset.analyticsEventProperties) }
      }

      unconditionalTrackEvent(analyticsEvent, this.tempEventProperties)

      this.tempEventProperties = {}
      this.tempEventName = ""
    }
  }

  trackChangeAmountEvent(e) {
    if (e.currentTarget.dataset.analyticsEvent) {
      const targetCurrency = document.querySelector('span[data-settings-target="currencyText"]');
      if (!targetCurrency) return

      e.currentTarget.dataset.analyticsEventProperties = JSON.stringify({ ...JSON.parse(e.currentTarget.dataset.analyticsEventProperties), target_currency: targetCurrency.textContent.toLowerCase() })
      this.oneTimeTrackEvent(e, true)
    }
  }

  _getEventAttributes(e) {
    if (e?.currentTarget?.dataset?.analyticsEventProperties === undefined) {
      return {}
    }

    return JSON.parse(e?.currentTarget?.dataset?.analyticsEventProperties)
  }
}
