import { Controller } from "stimulus";
import { EventMoreContentLoaded, EventPercentChangeLoaded, EventPriceLoaded, EventTooltipLoaded } from "../../events";

export default class extends Controller {
  static targets = ["content", "loadMoreButton", "totalShownResultsCount", "meta"]

  connect() {
    const { overridePageCount, contentCount, pageSize } = this.element.dataset;

    // Content count/page size unavailable, assume countless.
    this.countless = !+contentCount || !+pageSize;
    const hasNext = this.element.dataset.hasNext === "true";

    this.page = +overridePageCount || 1;
    this.availablePages = !this.countless ? Math.ceil(+contentCount / +pageSize) : (hasNext ? 2 : 1);

    this.refreshLoadMoreButton();
  }

  async loadMoreContent(e) {
    const url = new URL(e.currentTarget.dataset.url);
    url.searchParams.set("page", this.page + 1);

    this.loadMoreButtonTarget.disabled = true;
    const response = await fetch(url);

    // Assume no more content if 204 No Content / 404 Not Found
    if (response.status === 204 || response.status === 404) {
      this.loadMoreButtonTarget.remove();
      return;
    }

    if (response.status === 200) {
      if (this.loadMoreButtonTarget.dataset.removeButton === "true") {
        this.loadMoreButtonTarget.remove();
      }

      this.contentTarget.innerHTML += await response.text();
      this.page++;
      this.parseMetaTarget();
      this.refreshLoadMoreButton();
      this.refreshTotalResultsCount();

      this.element.dispatchEvent(new CustomEvent(EventMoreContentLoaded, { bubbles: true }));
      this.element.dispatchEvent(new CustomEvent(EventPriceLoaded, { bubbles: true }));
      this.element.dispatchEvent(new CustomEvent(EventPercentChangeLoaded, { bubbles: true }));
      this.element.dispatchEvent(new CustomEvent(EventTooltipLoaded, { bubbles: true }));
    }
  }

  refreshLoadMoreButton() {
    if (!this.hasLoadMoreButtonTarget) {
      return;
    }

    this.loadMoreButtonTarget.disabled = false;

    if (this.page >= this.availablePages) {
      this.loadMoreButtonTarget.remove();
    }
  }

  refreshTotalResultsCount() {
    if (!this.hasTotalShownResultsCountTarget) {
      return;
    }

    const totalElementsCount = this.contentTarget.childElementCount
    this.totalShownResultsCountTarget.textContent = totalElementsCount.toString()
  }

  /**
   * When the pagination is countless, we need to know if there is more content or not on every
   * request. To achieve this, we attach a <meta> element that contains pagination metadata in the
   * AJAX response. Parsing the metadata ensures that the correct state is displayed to the user.
   */
  parseMetaTarget() {
    if (!this.hasMetaTarget) {
      return;
    }

    if (this.countless) {
      const hasNext = this.metaTarget.dataset.hasNext === "true";
      (hasNext
        ? this.availablePages++
        : this.loadMoreButtonTarget.remove())
    }

    this.metaTarget.remove();
  }
}
