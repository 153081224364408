import { Controller } from "stimulus";
import { enter } from "el-transition";
import {EventCheckUserLogin, EventPortfolioCreated} from "../../events";
import { getCSRFToken, setUpdatedCsrfMetaContent } from "../../util";
import { AD_INTEREST_KEYS } from "../../config";
import Cookies from "js-cookie";
import { untrackUser } from "../../analytics";

export default class extends Controller {
  static targets = [
      // Navbar items
      "loggedInItem", "loggedOutItem", "developerGroup", "subscribeButton", "candyItem", "portfolioItem", "portfolioMenu", "premiumItem",
      // Authorship Controls
      "authorshipGroup",
      // Export historical data login wall
      "exportDataButton", "exportDataDropdown",
      // Portfolio guide
      "addCoinGeckoGuide",
      // Generic items
       "transition", "placeholder",
      // Ad
      "kevelAd"
  ];

  connect() {
    fetch("/accounts/logged_in_user.json", { credentials: "same-origin" })
      .then(async response => this._handleLoginState(response.ok ? await response.json() : null))

    this._removeUniversalLinkFragment();
  }

  _handleLoginState(data) {
    const isLoggedIn = !!data?.current_user;

    // Show/hide login/sign up buttons and user account menu buttons.
    this.loggedInItemTargets.forEach(target => { target.classList.toggle("tw-hidden", !isLoggedIn) });
    this.loggedOutItemTargets.forEach(target => { target.classList.toggle("tw-hidden", isLoggedIn) });

    // Handle more complex logged in/out states.
    isLoggedIn ? this._handleLoggedIn(data) : this._handleLoggedOut();

    // Fade in transition targets after updating state.
    this.placeholderTargets.forEach(target => { target.classList.add("!tw-hidden") });
    this.transitionTargets.forEach(target => {
      target.classList.remove("!tw-hidden");
      enter(target, "fade");
    });

    // Inform listeners about the recent login change.
    window.dispatchEvent(new CustomEvent(EventCheckUserLogin, { detail: { userLoggedIn: isLoggedIn } }));
  }

  _handleLoggedIn(data) {
    const isPremium = !!data.has_subscription;
    const hasApiAccess = !!data.has_enterprise_api_subscription;
    const hasAuthorship = !!data.has_authorship;

    // Toggle logged in state; developer dashboard button, premium button, and candy link.
    this.subscribeButtonTargets.forEach(target => { target.classList.toggle("tw-hidden", isPremium) });
    this.premiumItemTargets.forEach(target => { target.classList.toggle("tw-hidden", !isPremium) });
    this.developerGroupTargets.forEach(target => { target.classList.toggle("tw-hidden", !hasApiAccess) });
    this.authorshipGroupTargets.forEach(target => { target.classList.toggle("tw-hidden", !hasAuthorship) });
    this.candyItemTargets.forEach(target => { target.href = target.dataset.url });

    // Dynamically load portfolio dropdown.
    this._loadPortfolioMenu();
    window.addEventListener(EventPortfolioCreated, () => {
      this._loadPortfolioMenu();
    });

    // Add uuid to kevel ad
    this.kevelAdTargets.forEach(target => {
      if (target.dataset.adProp !== undefined && target.dataset.adProp !== "null") {
        let adProp = JSON.parse(target.dataset.adProp);
        adProp.requestBody.user = { "key" : data.current_user.uuid };
        target.dataset.adProp = JSON.stringify(adProp);
      }
    });

    const interestData = data.ad.interest_data;
    if (typeof googletag !== "undefined" && typeof googletag.cmd !== "undefined") {
      googletag.cmd.push(() => {
      googletag.pubads().setTargeting("kevelcoin", interestData?.coins)
        .setTargeting("kevelchain", interestData?.chains)
        .setTargeting("kevelcategories", interestData?.categories);
      });
    }

    if (data.first_sign_in) {
      this._syncLocalAdInterestDataToBackend();
    }

    this._setUserOnboardingCookie();
  }

  _handleLoggedOut() {
    // Require login to export historical data.
    this.exportDataDropdownTargets.forEach(e => { e.remove() });
    this.exportDataButtonTargets.forEach(e => {
      e.onclick = (e) => {
        e.stopImmediatePropagation();
        Modal.show("auth_modal");
      }
    });

    // Modal for sign up funnel; shown after 3 consecutive coin page visits.
    if (this.hasAddCoinGeckoGuideTarget) {
      this.addCoinGeckoGuideTarget.classList.remove("tw-hidden");
    }
  }


  async _syncLocalAdInterestDataToBackend() {
    const coins = localStorage.getItem(AD_INTEREST_KEYS["coins"]);
    const categories = localStorage.getItem(AD_INTEREST_KEYS["categories"]);
    const chains = localStorage.getItem(AD_INTEREST_KEYS["chains"]);
    const developer = localStorage.getItem(AD_INTEREST_KEYS["developer"]);
    const syncedCookieName = "signUpInterestDataSynced";
    const syncedCookie = Cookies.get(syncedCookieName);

    if (syncedCookie !== "true" && (!!coins || !!categories || !!chains || !!developer)) {
      let interestData = {};

      for (const key in AD_INTEREST_KEYS) {
        const data = localStorage.getItem(AD_INTEREST_KEYS[key]);
        if (!!data) {
          interestData[key] = JSON.parse(data);
        }
      }

      setUpdatedCsrfMetaContent();

      setTimeout(async () => {
        const endpoint = `/user_ad_properties`;
        const body = {
          interest_data: interestData
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            credentials: "same-origin",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify(body),
        };

        const response = await fetch(endpoint, requestOptions);

        if (response.status === 200) {
          Cookies.set(syncedCookieName, true);
          for (const key in AD_INTEREST_KEYS) {
            localStorage.removeItem(AD_INTEREST_KEYS[key]);
          }
        }
      }, 4000);
    }
  }

  _setUserOnboardingCookie() {
    const userOnboardingDataController = document.querySelectorAll("#userOnboarding");
    if (userOnboardingDataController.length === 0) {
      Cookies.set("close-user-onboarding", true, { expires: 365 })
    }
  }

  _loadPortfolioMenu() {
    // Clear existing dropdown menu items if necessary, used when reloading.
    const menuGroupTargets = this.portfolioMenuTarget.querySelectorAll(".portfolio-menu-group");
    menuGroupTargets.forEach(target => target.remove());

    // Fetch portfolios dropdown and add into target.
    fetch(`/${I18n.locale}/portfolios/portfolios_dropdown`, {
      headers: { "X-CSRF-Token": getCSRFToken() },
      method: "GET",
      credentials: "same-origin",
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`Could not retrieve portfolio dropdown. Error ${response.status}.`);
        }

        const html = await response.text();
        this.portfolioMenuTarget.insertAdjacentHTML("beforeend", html);

        // Allow hover if portfolio dropdown loaded successfully.
        let actions = this.portfolioItemTarget.dataset.action.split(" ");
        actions.push("mouseover->navbar#handleOver", "mouseout->navbar#handleAway");
        this.portfolioItemTarget.dataset.action = actions.join(" ");

        // Disable link if portfolio dropdown loaded successfully.
        this.portfolioItemTarget.querySelector("a").removeAttribute("href");
      });
  }

  _removeUniversalLinkFragment() {
    if (window.location.hash === "#omniauth_success" || window.location.hash === "#omniauth_failure") {
      history.replaceState(null, null, window.location.pathname + window.location.search);
    }
  }
}
