import { Controller } from "stimulus";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  initialize() {
    if(process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: "https://b9d74d9f9f5ffb2451d8883fe57f25c7@o4508323025911808.ingest.us.sentry.io/4508339685621761",

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        // release: "coinanalytics@1.0.0",
        integrations: [
          // Sentry.browserTracingIntegration(),
          // Sentry.replayIntegration(),
        ],
        ignoreErrors: [
          "Failed to read a named property 'performance' from 'Window':", // top non-actionable error
          "Failed to read a named property 'clearTimeout' from 'Window':",
          "Load failed", // from ad blocker
          "Failed to fetch",
          "Blocked a restricted frame with origin",
          "undefined is not an object (evaluating 'this.calendarInstance.calendarContainer.addEventListener')",
          "Can't find variable: activeTab",
          "undefined is not an object (evaluating 'r.converted_last.btc')",
          "k.pubads is not a function",
          "No error message", // from websocket
          "No connection", // from websocket
          "undefined is not an object (evaluating 'this.optInMixpanel')", // from mixpanel
          "Cannot read properties of undefined (reading 'optOutAnalytics')", // from mixpanel
          "this is undefined", // from mixpanel
          "Can't find variable: Sentry", // from mixpanel
        ],
        sampleRate: 0.1,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        // tracePropagationTargets: ["coingecko.com", /^https:\/\/(www\.)?coingecko\.com\//],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 0.1,
      });
    }
  }
}
