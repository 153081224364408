import KevelBaseController from "./kevel_base_controller";
import { AD_TEMPLATES } from "../../../template/ad_templates";
import { sendImpression } from "../../../util/kevel";
import showAds from "../../../util/show_ads";

export default class extends KevelBaseController {
  static targets = ["body"];

  connect() {
    this.clicked = false;
    this.adData = null;
    this.isLoadingAdData = [];

    if (showAds() && this.viewportAd()) {
      this.loadAdIfAdAtViewport();
    }
  }

  async sendImpression() {
    if (showAds()) {
      this.adProp = this.getAdProp();

      if (this.dataKeywordExist()) {
        this.attachKeywordToRequestBody();
      };

      if (this.dataAdQueryExist()) {
        this.attachAdQueryToRequestBody();
      };

      if (this.adDataNotLoaded()) {
        await this.loadAdData();
      }

      if (this.adDataNotNull()) {
        await this._renderAd();
        this.injectEventDataToAdLink(this.bodyTarget);
        this._sendAllImpressions();
      }
    }
  }

  async redirectToTradePage(e) {
    e.preventDefault();
    const tradeUrl = e.currentTarget.href;
    const clickUrl = e.currentTarget.dataset.clickUrl + "&noredirect";
    window.open(tradeUrl, "_blank");
    const response = await fetch(clickUrl);

    if (response.status !== 200) {
      // add error message to sentry
    }
  }

  async _renderAd() {
    const templateName = this.adProp.templateName;
    let adBody = "";
    this.impressionLinkArr = [];

    for (const adData of this.adData) {
      const mergedCustomData = { ...adData.adContentData, ...await this._getTickerPriceData(adData.adContentData["ctid"]) };

      adBody += AD_TEMPLATES[templateName](
        adData.link,
        adData.impressionLink,
        mergedCustomData,
        this.bodyTarget.dataset.cgLogoUrl,
        this.bodyTarget.dataset.sponsored,
        adData.flightId,
      );
      this.impressionLinkArr.push(adData.impressionLink);
    };

    this.bodyTarget.innerHTML = adBody;
    this.bodyTarget.classList.remove("tw-hidden");
  }

  async _sendAllImpressions() {
    if (!this.clicked) {
      this.clicked = this.impressionLinkArr.map(async link => {
        return await sendImpression(link);
      });
    }
  }

  async _getTickerPriceData(id) {
    if (this.isLoadingAdData.includes(id)) return {}; // Avoid duplicate calls
    this.isLoadingAdData.push(id);
    const totalVolumeBtc = this.bodyTarget.dataset.totalVolumeBtc;
    const endpoint = `/${document.body.dataset.locale}/tickers/${id}/price_data?total_volume_btc=${totalVolumeBtc}`;
    const requestOptions = {
      credentials: "same-origin",
    };
    const response = await fetch(endpoint, requestOptions);
    return response.json();
  }
}
