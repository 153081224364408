import { replaceAssetURL } from "../util/kevel";
import { formatCurrency, getActiveCurrency } from "../util/currency";
import { SELECT_AD_UNIT_EVENT } from "../mixpanel_config";

export const AD_TEMPLATES = {
  nft: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-p-3">
        ${trackingPixelImageHtml}
        <div class="tw-grid tw-grid-cols-3 tw-gap-2">
          <div>
            <img src=${replaceAssetURL(adContentData["ctButtonImageUrl"])} width="105px" class="tw-max-w-[105px] tw-rounded-md">
          </div>
          <div class="tw-col-span-2 tw-flex tw-flex-col tw-gap-4">
            <div>
              <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-5 tw-w-5">
                <a href="${link}"
                  target="_blank"
                  data-action="click->analytics-tracker#unconditionalTrackEvent"
                  data-analytics-event="${SELECT_AD_UNIT_EVENT}"
                  data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
                >
                  ${adContentData["ctTitle"]}
                </a>
            </div>
            <div>
              ${adContentData["ctBody"]}
            </div>
          </div>
        </div>
      </div>`;
  },
  coinConverter: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-flex tw-flex-col tw-gap-3 tw-p-2">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-items-center">
          <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-5 tw-w-5 tw-mr-2"/>
          <span class="tw-text-gray-700 dark:tw-text-moon-200 tw-text-xs tw-font-semibold">
            ${adContentData["ctTitle"]}
          </span>
        </div>
        <a href="${link}"
          target="_blank"
          class="tw-flex tw-flex-col tw-gap-3"
          data-action="click->analytics-tracker#unconditionalTrackEvent"
          data-analytics-event="${SELECT_AD_UNIT_EVENT}"
          data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
        >
          <div>
            <img src=${replaceAssetURL(adContentData["ctButtonImageUrl"])} class="tw-rounded-md tw-w-48">
          </div>
          <div class="tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-font-medium">
            ${adContentData["ctBody"]}
          </div>
          <div class="tailwind-reset">
            <button type="button" class="tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow !tw-mb-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover active:!tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-items-center tw-justify-center tw-font-semibold tw-text-inline tw-rounded-lg tw-select-none focus:tw-outline-none tw-px-4 tw-py-2.5 tw-inline-flex tw-w-full">
              <div class="tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900 dark:focus:tw-text-primary-900 tw-font-semibold tw-text-sm tw-leading-5">
                ${adContentData["ctCTA"]}
              </div>
            </button>
          </div>
        </a>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-right">
          <img src=${geckoImageLink} class="tw-h-5 tw-w-5" loading="lazy">
          <div class="tw-text-xs tw-text-gray-500 dark:tw-text-white dark:tw-text-opacity-60 tw-font-medium">
            ${sponsored}
          </div>
        </div>
      </div>`;
  },
  search: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-px-4 tw-flex tw-flex-col tw-gap-1 tw-mt-4 xl:tw-mt-0">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <div>
            <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-w-5">
          </div>
          <div>
            <a href="${link}"
              target="_blank"
              data-action="click->analytics-tracker#unconditionalTrackEvent"
              data-analytics-event="${SELECT_AD_UNIT_EVENT}"
              data-analytics-event-properties='{ "ad_title": "${adContentData["ctBody"]}", "ad_flight_id": "${flightId}" }'
            >
              ${adContentData["ctBody"]}
            </a>
          </div>
        </div>
        <div class="tw-text-xs">
          ${sponsored}
        </div>
      </div>`;
  },
  searchNew: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <a href="${link}"
        target="_blank"
        class="tw-flex tw-justify-between tw-border-solid tw-border-2 tw-border-gray-200 dark:tw-border-moon-700 tw-rounded-md tw-p-2"
        data-action="click->analytics-tracker#unconditionalTrackEvent"
        data-analytics-event="${SELECT_AD_UNIT_EVENT}"
        data-analytics-event-properties='{ "ad_title": "${adContentData["ctBody"]}", "ad_flight_id": "${flightId}" }'
      >
        ${trackingPixelImageHtml}
        <div class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-medium tw-text-sm tw-leading-5 tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-xs">
          <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-mr-1 tw-h-4 tw-w-4">
          ${adContentData["ctBody"]}
        </div>
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium tw-flex tw-items-center">
          <img src=${geckoImageLink} width="15px" class="tw-mr-1"> ${sponsored}
          <i class="fas fa-times tw-text-sm tw-ml-1 tw-text-gray-500 dark:tw-text-white dark:tw-opacity-60 tw-cursor-pointer" data-action="click->remove-element#removeElement"></i>
        </div>
      </a>`;
  },
  coinOverview: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-2">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-6 tw-w-6">
          <div class="tw-text-xs tw-text-gray-700 dark:tw-text-moon-50 tw-font-medium">
            ${adContentData["ctTitle"]}
          </div>
        </div>
        <div>
          <a href="${link}"
            target="_blank"
            class="tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow !tw-mb-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover active:!tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-items-center tw-justify-center tw-font-semibold tw-text-inline tw-rounded-lg tw-select-none focus:tw-outline-none tw-px-2.5 tw-py-1.5 tw-inline-flex"
            data-action="click->analytics-tracker#unconditionalTrackEvent"
            data-analytics-event="${SELECT_AD_UNIT_EVENT}"
            data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
          >
            <div class="tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900 dark:focus:tw-text-primary-900 tw-font-semibold tw-text-xs">
              ${adContentData["ctCTA"]}
            </div>
          </a>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-justify-between tw-items-start">
        <div class="tw-flex-1">
          <div class="tw-text-xs tw-text-gray-700 dark:tw-text-moon-50 tw-font-medium">
            ${adContentData["ctHeadline"]}
          </div>
          <div class="tw-mt-2 tw-break-words tw-text-xs tw-text-gray-500 dark:tw-text-moon-200">
            ${adContentData["ctBody"]}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-1 tw-text-right">
          <img src=${geckoImageLink} class="tw-h-5 tw-w-5">
          <div class="tw-text-xs tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
            ${sponsored}
          </div>
        </div>
      </div>`;
  },
  coinPageButton: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-p-3">
        ${trackingPixelImageHtml}
        <div class="tw-grid tw-grid-cols-3 tw-gap-3">
          <div>
            <img src=${replaceAssetURL(adContentData["ctButtonImageUrl"])} class="tw-w-[105px] tw-rounded-md">
          </div>
          <div class="tw-col-span-2 tw-flex tw-flex-col">
            <a href="${link}"
              target="_blank"
              data-action="click->analytics-tracker#unconditionalTrackEvent"
              data-analytics-event="${SELECT_AD_UNIT_EVENT}"
              data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
            >
              <div class="tw-flex tw-items-center">
                <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-5 tw-w-5 tw-mr-1">
                <span class="tw-font-medium tw-text-gray-700 dark:tw-text-moon-50 tw-text-sm">${adContentData["ctTitle"]}</span>
              </div>
              <div>
                <div class="tw-text-gray-700 dark:tw-text-moon-50 tw-text-xs tw-font-semibold tw-mt-2 tw-mb-1">
                  ${adContentData["ctTagline"]}
                </div>
                <div>
                  <span class="tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-font-medium">${adContentData["ctBody"]}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>`;
  },
  articleAd: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <a href=${link}
        rel="sponsored noopener"
        target="_blank"
        class="non-body"
        data-action="click->analytics-tracker#unconditionalTrackEvent"
        data-analytics-event="${SELECT_AD_UNIT_EVENT}"
        data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
      >
        ${trackingPixelImageHtml}
        <div class="non-body tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-p-6 tw-my-6 tw-rounded-xl tw-border-2 dark:tw-border-moon-700 tw-border-gray-200">
          <div class="non-body md:tw-mx-auto tw-my-auto">
            <img src=${replaceAssetURL(adContentData["ctIconURL"])}>
          </div>
          <div class="tw-col-span-3">
            <div class="non-body tw-font-bold tw-text-gray-900 dark:tw-text-moon-50 tw-text-lg md:tw-text-xl tw-leading-7 tw-flex-grow tw-mb-2">
              ${adContentData["ctTitle"]}
            </div>
            <div class="non-body tw-font-normal tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm tw-leading-5 tw-flex-grow tw-mb-4">
              ${adContentData["ctBody"]}
            </div>
            <div class="non-body tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-4">
              <button class="non-body tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow tw-items-center tw-justify-center tw-font-semibold tw-text-inline
              tw-rounded-lg tw-select-none focus:tw-outline-none !tw-mb-1 !tw-mr-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover
              active:tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-px-4 tw-py-2.5 tw-inline-flex">
                <div class="non-body tw-text-xs tw-leading-4 tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900
                dark:focus:tw-text-primary-900 tw-font-semibold">
                  ${adContentData["ctCTA"]}
                </div>
              </button>
              <img src=${geckoImageLink} width="12px" class="non-body !tw-m-0">
              <span class="non-body tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-xs tw-leading-6 tw-flex-grow">${sponsored}</span>
            </div>
          </div>
        </div>
      </a>`
  },
  coinTickerRowAd: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <tr class="tw-h-10 tw-bg-success-50 dark:tw-bg-success-400/10" data-ads-target="banner" >
        ${trackingPixelImageHtml}
        <td></td>
        <td colspan="8" class="tw-py-2">
          <div class="tw-flex tw-gap-x-2">
            <a class="tw-flex tw-items-center tw-text-gray-900 dark:tw-text-moon-50 tw-text-sm tw-leading-5"
              rel="nofollow"
              href="${link}"
              target="_blank"
              data-action="click->analytics-tracker#unconditionalTrackEvent"
              data-analytics-event="${SELECT_AD_UNIT_EVENT}"
              data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
            >
              <img class="tw-mr-2 !tw-h-6 tw-w-6 tw-object-fill" src="${replaceAssetURL(adContentData["ctIconURL"])}" />
              ${adContentData["ctTitle"]}
            </a>

            <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-1">
              <img src=${geckoImageLink} class="tw-h-5 tw-w-5" loading="lazy">
              <div class="tw-text-xs tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
                ${sponsored}
              </div>
            </div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>`
  },
  coinNewsAd: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <a href="${link}"
        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 dark:tw-border-moon-700 tw-pb-5 tw-flex tw-flex-col"
        data-action="click->analytics-tracker#unconditionalTrackEvent"
        data-analytics-event="${SELECT_AD_UNIT_EVENT}"
        data-analytics-event-properties='{ "ad_title": "${adContentData["ctTitle"]}", "ad_flight_id": "${flightId}" }'
      >
        ${trackingPixelImageHtml}
        <img class="tw-object-cover tw-rounded-lg tw-aspect-[21/11] tw-w-full tw-mb-4" loading="lazy" src="${replaceAssetURL(adContentData["ctButtonImageUrl"])}">
        <div class="tw-flex tw-flex-col tw-flex-1 tw-col-span-7">
          <div class="tw-flex-1">
            <div class="tw-font-bold tw-text-gray-900 dark:tw-text-moon-50 tw-text-lg md:tw-text-xl tw-leading-7 tw-mb-4">
              ${adContentData["ctTitle"]}
            </div>
          </div>
          <div>
            <div class="tw-flex tw-items-center">
              <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-5 tw-w-5 tw-mr-2"/>
              <span class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-sm tw-leading-5 tw-mb-1">
                ${adContentData["ctBody"]}
              </span>
            </div>
            <div class="tw-font-normal tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm tw-leading-5">
              <time>${sponsored}</time>
            </div>
          </div>
        </div>
      </a>`
  },
  coinMarketsAd: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    const TRUST_SCORE_COLOR_CLASSES = {
      green: "tw-text-success-500 dark:tw-text-success-400",
      yellow: "tw-text-warning-600",
      red: "tw-text-danger-500",
      gray: "tw-text-gray-500"
    };

    const FX_CALCULATE_CURRENCY = "btc";
    const DEPTH_CURRENCY = "usd";
    const activeCurrency = getActiveCurrency();

    const convertedLastBtc = adContentData["converted_last"]["btc"];
    const convertedLast = formatCurrency(
      fx(convertedLastBtc).from(FX_CALCULATE_CURRENCY).to(activeCurrency),
      activeCurrency);
    const convertedVolumeBtc = adContentData["converted_volume"]["btc"];
    const convertedVolume = formatCurrency(
      fx(convertedVolumeBtc).from(FX_CALCULATE_CURRENCY).to(activeCurrency),
      activeCurrency);
    const bidAskSpread = adContentData["bid_ask_spread"];
    const bidAskSpreadPercentage = (parseFloat(bidAskSpread) * 100).toFixed(2);
    const upperDepthTwoPercent = adContentData["upper_depth"]["two_percent"];
    const upperDepthTwoPercentConverted = formatCurrency(upperDepthTwoPercent, DEPTH_CURRENCY);
    const lowerDepthTwoPercent = adContentData["lower_depth"]["two_percent"];
    const lowerDepthTwoPercentConverted = formatCurrency(lowerDepthTwoPercent, DEPTH_CURRENCY);

    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <tr class="hover:tw-bg-gray-50 tw-bg-white dark:tw-bg-moon-900 hover:dark:tw-bg-moon-800 tw-text-sm">
        <td class="tw-pl-2.5 tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <i class="fa-solid fa-rectangle-ad tw-text-primary-500 dark:tw-text-primary-400"></i>
        </td>
        <td class="tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <div class="tw-flex tw-items-center tw-gap-x-2">
            <a class="tw-flex tw-items-center tw-whitespace-nowrap" href="${adContentData["ctexchange_link"]}">
              <img class="tw-mr-1 !tw-h-6 tw-w-6 tw-object-fill" loading="lazy" alt="${adContentData["ctexchange_name"]}" src="${adContentData["externalUrl"]}">
              <div class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-sm tw-leading-5">
                ${adContentData["market_name"]}
              </div>
            </a>
            <div class="tw-flex tw-gap-x-2">
            </div>
          </div>
        </td>
        <td class="tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <span class="tw-inline-flex tw-items-center tw-px-1.5 tw-py-0.5 tw-bg-gray-100 dark:tw-bg-moon-400/20 tw-rounded-md">
            <div class="tw-text-xs tw-leading-4 tw-text-gray-700 dark:tw-text-moon-200 tw-font-medium">
              ${adContentData["ctmarket_type"] === "spot" ? "CEX" : "DEX"}
            </div>
          </span>
        </td>
        <td class="tw-flex tw-gap-x-2 tw-items-center tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <div class="tw-flex tw-gap-x-2 tw-whitespace-nowrap">
            <a target="_blank"
              rel="nofollow noopener"
              href="${adContentData["ctoverride_link"] || adContentData["cttrade_link"]}"
              class="tw-cursor-pointer tw-font-semibold tw-no-underline tw-text-slate-700 hover:tw-text-primary-500 dark:tw-text-moon-50 dark:hover:tw-text-primary-400"
              data-action="click->coin-markets-ads#redirectToTradePage click->analytics-tracker#unconditionalTrackEvent"
              data-click-url="${link}"
              data-analytics-event="${SELECT_AD_UNIT_EVENT}"
              data-analytics-event-properties='{ "ad_title": "${adContentData["market_name"]}", "ad_flight_id": "${flightId}" }'
            >
              ${adContentData["ctpair"]}<i class="tw-ml-1 fas fa-arrow-up-right-from-square"></i>
            </a>
          </div>
        </td>
        <td class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <span data-price-target="price" data-test="true" data-price-btc="${convertedLastBtc}" data-prev-price="">${convertedLast}</span>
        </td>
        <td data-sort="${bidAskSpread}" class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          ${bidAskSpreadPercentage}%
        </td>
        <td data-sort="${upperDepthTwoPercent}" class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          ${upperDepthTwoPercentConverted}
        </td>
        <td data-sort="${lowerDepthTwoPercent}" class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          ${lowerDepthTwoPercentConverted}
        </td>
        <td data-sort="${convertedVolumeBtc}" class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <span data-price-target="price" data-price-btc="${convertedVolumeBtc}" data-prev-price="">${convertedVolume}</span>
        </td>
        <td data-sort="${convertedVolumeBtc}" class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          ${adContentData["volume_percentage"]}%
        </td>
        <td class="tw-text-end tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <div class="tw-flex tw-gap-x-2"><span class="tw-whitespace-nowrap tw-flex-1">${adContentData["last_updated"]}</span></div>
        </td>
        <td class="tw-text-center tw-px-1 tw-py-2.5 2lg:tw-p-2.5 tw-bg-inherit tw-text-gray-900 dark:tw-text-moon-50">
          <i class="fas fa-circle ${TRUST_SCORE_COLOR_CLASSES[adContentData["trust_score"]]}"></i>
        </td>
      </tr>`
  },
  coinConverterNew: (link, impressionLink, adContentData, geckoImageLink, sponsored, flightId) => {
    let trackingPixelImageHtml = "";

    if (adContentData["ctPixel"]) {
      trackingPixelImageHtml = `<img style="display: none;" src="${adContentData["ctPixel"]}">`;
    }

    return `
      <div class="tw-flex tw-flex-col tw-gap-2">
        ${trackingPixelImageHtml}
        <a href="${link}"
          target="_blank"
          class="tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow !tw-mb-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover active:!tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-items-center tw-justify-center tw-font-semibold tw-text-inline tw-rounded-lg tw-select-none focus:tw-outline-none tw-px-2.5 tw-py-1.5 tw-inline-flex tw-w-full"
          data-action="click->analytics-tracker#unconditionalTrackEvent"
          data-analytics-event="${SELECT_AD_UNIT_EVENT}"
          data-analytics-event-properties='{ "ad_title": "${adContentData["ctCTA"]}", "ad_flight_id": "${flightId}" }' >
          <div class="tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900 dark:focus:tw-text-primary-900 tw-font-semibold tw-text-sm tw-leading-5">
            ${adContentData["ctCTA"]} <i class="fas fa-external-link"></i>
          </div>
        </a>
        <div class="tw-flex tw-justify-center tw-items-center tw-gap-1">
          <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular">
            ${sponsored}
          </div>
          <img src=${replaceAssetURL(adContentData["ctIconURL"])} class="tw-h-3"/>
        </div>
      </div>`;
  },
}
